@import '~antd/dist/antd.css';
@import 'bootstrap';
@import 'variables';

@import 'desktop/main';

// pages
@import 'desktop/landing';
@import 'desktop/public_support';
@import 'desktop/work_regulation';
@import 'desktop/dashboard';
@import 'desktop/labor_social';
@import 'desktop/employee_register';

// components
@import 'desktop/components/landing_navbar';
@import 'desktop/components/landing_footer';
@import 'desktop/components/dashboard_navbar';
@import 'desktop/components/inquiry';
@import 'desktop/components/interview';
@import 'desktop/components/labor_social_navbar';

@media all and (max-width: 768px) {
    @import 'mobile/main';

    @import 'mobile/employee_register';
    @import 'mobile/labor_social';
    @import 'mobile/dashboard';

    @import 'mobile/components/labor_social_navbar';
    @import 'mobile/components/dashboard_navbar';
    @import 'mobile/components/kouteki';
}
