.general-cancel-modal {
    .title-modal {
        color: #2e353a;
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        text-align: center;
        margin: 30px 0;
    }
    .subtitle-modal {
        color: #e95532;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
        margin-bottom: 30px;
    }

    .ant-modal-close {
        top: 20px;
        right: 20px;

        &-x {
            width: auto;
            height: auto;
            line-height: 20px;

            span {
                font-size: 32px !important;
            }
        }
    }

    .cancel-info {
        display: flex;
        border-top: 1px solid #d4e1ea;
        color: #2e353a;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;

        &-left {
            position: relative;

            &::before {
                position: absolute;
                top: 50%;
                right: 0;
                width: 1.5px;
                height: 18px;
                background-color: #cce1f0;
                transform: translateY(-50%);
                transition: background-color 0.3s;
                content: '';
            }
        }
    }

    .form-buttons {
        display: flex;
        margin-top: 30px;

        .general-button {
            border-radius: 27px !important;
            height: auto;
            padding: 16px;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            transition: ease-in-out 0.3s;

            &:hover {
                opacity: 0.7;
                transition: ease-in-out 0.3s;
            }
        }

        .cancel-button {
            width: 200px;
            background: transparent;
            color: #006cb6;
            border: 1px solid #006cb6;
        }

        .submit-button {
            width: 320px;
            background: #006cb6;
            color: #ffffff;
            border: 1px solid #006cb6;
        }
    }
}
