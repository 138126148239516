.employee-management-subtitle {
    font-family: Hiragino Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.employee-management-table-container {
    margin: 20px 0px;
}

.employee-management-edit-button {
    background: #e6f7ff;
    color: #006bb5;
    border: 1px solid #006bb5;
    width: inherit;
}

.employee-management-action-buttons {
    width: 150px;
}
