.meeting-details {
    display: flex;
    font-size: 14px;
    margin-bottom: 40px;

    &-div-top {
        padding: 16px !important;
        border-top: 1px solid #d4e1ea;
        border-bottom: 1px solid #d4e1ea;
    }

    &-div {
        padding: 16px !important;
        border-bottom: 1px solid #d4e1ea;
    }

    &-row {
        align-items: center !important;
    }

    &-label {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-align: left;
        color: #2e353a;
        border-right: 1px solid #d4e1ea;
    }

    &-value {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-align: left;
        color: #2e353a;
        padding-left: 32px;

        .copy-clipboard {
            text-align: right !important;
            text-decoration: underline !important;
            cursor: pointer !important;
        }

        .copy-icon {
            padding-right: 8px !important;
        }
    }

    &-date-right {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #2e353a;
    }

    &-date {
        color: #006cb6;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        padding-left: 32px;
    }

    &-grant-title {
        color: #2e353a;
        font-size: 14px;
        line-height: 21px;
        font-weight: 700;
        padding-left: 32px;
    }

    &-discussion-title {
        font-size: 20px;
        color: #006cb6;
        font-weight: 700;
    }

    &-background {
        background-color: #f2f7fb;
        align-items: center;
        border-radius: 4px !important;
    }

    &-requirements {
        background-color: #ffffff;
        padding: 12px;

        .title {
            font-weight: 700;
            font-size: 17px;
        }
    }

    &-border {
        border-color: #d4e1ea !important;
        padding-top: 8px;
    }

    &-remarks-title {
        color: #2e353a;
        font-size: 14px;
        font-weight: 700 !important;
        line-height: 21px;
    }

    &-remarks-content {
        color: #2e353a;
        font-size: 14px;
        font-weight: 400 !important;
        line-height: 21px;
    }

    &-title {
        margin: 0px 25px 0px 10px;
        align-content: center;
        text-align: center;
        color: #006cb6;
        font-size: 24px;
        font-weight: 700 !important;
        line-height: 36px;
    }

    &-card {
        width: 110px !important;
    }

    &-status {
        margin: auto;
        padding: 2px 12px !important;
    }

    &-back-button {
        color: #006cb6;
        border-color: #006cb6;
    }

    &-back-button:hover {
        background-color: #f2f7fb;
    }

    &-accordion-button {
        color: white;
        border-color: #006cb6;
        background-color: #006cb6;
        border-radius: 20px !important;
    }

    &-action-buttons {
        margin-top: 30px !important;

        .checkbox-label {
            text-align: right !important;
        }
    }

    &-change-schedule-btn {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #ffffff;
        background-color: #e97306;
        border-radius: 20px !important;
    }

    &-cancel-btn {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #e95532;
        border-color: #e95532;
        background-color: #ffffff;
        border-radius: 20px !important;
        margin-left: 24px !important;
    }

    .card-container {
        display: flex;
        justify-content: center;
        align-self: center;

        &-scheduled {
            background-color: #fcf1e6;
            border: 1px solid #e97306;
        }

        &-interviewed {
            background-color: #ffffff;
            border: 1px solid #006cb6;
        }

        &-cancelled {
            background-color: #ffffff;
            border: 1px solid #e95532;
        }

        &-not-implemented {
            background-color: #f4f5f5;
            border: 1px solid #848f97;
        }

        &-default {
            background-color: #ffffff;
            border: 1px solid #848f97;
        }
    }

    .status {
        &-scheduled {
            color: #e97306 !important;
            font-weight: 400 !important;
        }

        &-interviewed {
            color: #006cb6 !important;
            font-weight: 400 !important;
        }

        &-cancelled {
            color: #e95532 !important;
            font-weight: 400 !important;
        }

        &-not-implemented {
            color: #848f97 !important;
            font-weight: 400 !important;
        }

        &-default {
            color: #848f97 !important;
            font-weight: 400 !important;
        }
    }

    .round-button {
        border-radius: 19px !important;
    }
}
