.login-form-container {
    .login {
        width: 95vw;
        padding: 50px 20px;
    }
}

.forgot-password-success-container,
.login-form-container {
    position: inherit;
    height: inherit;
    margin: 50px auto;
    padding: 0 10px;
    width: 100%;

    .success-modal {
        width:inherit !important
    }
}