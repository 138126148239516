.general-cancel-modal {
    .title-modal {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 10px;
    }
    .subtitle-modal {
        font-size: 12px;
        margin-bottom: 20px;
    }

    .ant-modal-close .ant-modal-close-x span {
        font-size: 25px !important;
    }

    .cancel-info {
        font-size: 12px;
    }


    .form-buttons {
        justify-content: space-between;
        margin-top: 20px;

        .general-button {
            padding: 12px 10px;
            font-size: 12px;
        }

        .cancel-button, .submit-button {
            width: 49%;
        }
    }
}
