body#work-regulation .inquiries,
body#public-support .inquiries {
    .a-content ul {
        list-style-type: none;
    }

    .contact-us-form {
        .title {
            color: #01A0DD;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            display: block;
            text-align: center;
        }
    }

    .ant-collapse-content-box {
        position: relative;
        background-color: #fafafa;
    }

    .a-char, .a-content, .q-question, .q-char {
        color: #000000;
        font-style: normal;
    }

    .a-char, .q-char {
        text-align: center;
    }

    .a-char {
        text-align: left;
        padding-left: 2.5%;

        font-weight: 600;
        font-size: 24px;
        line-height: 14px;
    }

    .a-content {
        padding-left: 8.5%;
        font-weight: normal;
        font-size: 16px;

    }

    .q-char, .q-question {
        position: absolute;
        line-height: 14px;
    }

    .q-char {
        left: 2.97%;
        right: 95.18%;
        top: 38.33%;
        bottom: 38.33%;

        font-weight: 600;
        font-size: 24px;
    }

    .q-question {
        left: 9.36%;
        right: 5%;
        top: 38.33%;
        bottom: 38.33%;

        font-weight: normal;
        font-size: 18px;
    }

    .ant-collapse-header {
        word-wrap: break-word;
        height: 60px;
        left: 0;
        top: 0;
        border-radius: 3px;
    }
}
