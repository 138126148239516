// Calendar customization
.change-schedule-modal {
    /* Default width */
    width: 968px !important;

    // Reduce modal size
    .ant-modal-content {
        zoom: 0.78;
        flex-direction: column;
        overflow: hidden;
        font-size: 0.75rem;
    }

    // Reduce calendar section size to fit 100% screen size
    .schedule-time > label {
        height: 49px;
        padding-top: 13px !important;
    }

    // Reduce calendar section size to fit 100% screen size
    .ant-picker-panel .ant-picker-calendar-date {
        height: 60px !important;
        font-size: 0.75rem !important;
    }

    .ant-modal-body {
        padding: 35px !important;
    }

    .title {
        font-size: 32px;
        line-height: 48px;
        font-weight: 700;
        text-align: center;
        color: #2e353a;
        margin-bottom: 40px !important;
    }

    .details-div-top {
        padding: 16px !important;
        border-top: 1px solid #d4e1ea;
        border-bottom: 1px solid #d4e1ea;
    }

    .details-div {
        padding: 16px !important;
        border-bottom: 1px solid #d4e1ea;
        margin-bottom: 40px !important;
    }

    .details-row {
        align-items: center !important;
    }

    .details-label {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        text-align: left;
        color: #2e353a;
        border-right: 1px solid #d4e1ea;
    }

    .details-value {
        white-space: pre-line;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        text-align: left;
        color: #2e353a;
        padding-left: 32px;
    }

    .schedule-calendar {
        margin-bottom: 40px !important;

        //Calendar customization
        .ant-picker-calendar-date-value {
            text-align: left !important;
            font-size: 12px !important;
            font-weight: 400 !important;
            line-height: 24px !important;
        }

        .ant-picker-cell {
            border: 1px solid #cccccc !important;
        }

        .ant-picker-cell-inner {
            border: none !important;
        }

        .ant-picker-content thead {
            border: 1px solid #cccccc !important;
        }

        .ant-picker-content tr {
            height: 48px;
            text-align: center !important;
        }

        .ant-picker-content th {
            border-right: 1px solid #cccccc;
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 24px !important;
            padding: 0 !important;
        }
    }

    .schedule-time-list {
        max-width: 22%;
        margin-left: 34px;
        margin-top: 11px !important;
    }

    .schedule-summary {
        font-size: 18px;
        line-height: 22px;
        color: black;
        font-weight: bolder;

        div {
            display: inline-block;
            border-bottom: 2px solid #006cb6;
            line-height: 25px;

            span.schedule-datetime {
                color: #006cb6;
            }
        }
    }

    .schedule-icons {
        text-align: right;
        padding-right: 0 !important;
        color: #2e353a;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        span {
            margin: 0 10px !important;
        }
        img {
            margin-bottom: 3px;
        }
    }

    .schedule-checker-icon {
        color: #e95532;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 23%;

        svg circle {
            stroke: #006cb6;
        }
    }

    .ant-picker-panel .ant-picker-calendar-date {
        height: 75px;
        padding: 4px 12px 0;
        margin: 0;
        border-top: none;
    }

    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date {
        background-color: #006cb6 !important;

        .ant-picker-calendar-date-value {
            color: #ffffff;
        }

        .schedule-checker-icon {
            svg circle {
                stroke: #ffffff;
            }
        }
    }

    .ant-picker-cell {
        &:hover {
            &:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(
                    .ant-picker-cell-range-hover-start
                ):not(.ant-picker-cell-range-hover-end) {
                .ant-picker-cell-inner {
                    background: #e5f0f7;
                }
            }
        }
    }

    .month-picker {
        display: flex;
        align-items: center;

        &-text {
            font-weight: bold;
            font-size: 16px;
        }

        button {
            font-size: 8px;
            padding: 0 5px;
            margin: 0 10px !important;
            height: 20px;
            border: 1px solid #006cb6;
            color: #006cb6;
        }
    }

    .schedule-time {
        width: 100% !important;
        text-align: center;

        .ant-radio-wrapper {
            border: 1px solid #a2adb4;
            border-radius: 4px;
            padding: 20px;
            margin: 6px 0;
            width: 100%;
            justify-content: center;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: #e5f0f7;
            }
        }

        .ant-radio-wrapper-disabled {
            background-color: #f4f5f5;
        }

        .ant-radio-wrapper-checked {
            border: 2px solid #006cb6;
        }
    }

    .ant-picker-cell-disabled {
        background-color: #f4f5f5;
        // color: #99C4E1 !important;
    }

    // Change color of sunday header
    .schedule-calendar > div > div > div > table > thead > tr > th:nth-child(1) {
        color: #e95532;
    }

    // Change color of sunday date
    .schedule-calendar > div > div > div > table > tbody > tr > td:nth-child(1) {
        color: #f6bbad !important;
    }

    // Change color of saturday date
    .schedule-calendar > div > div > div > table > thead > tr > th:nth-child(7) {
        color: #006cb6 !important;
    }

    // Change color of sunday date
    .schedule-calendar > div > div > div > table > tbody > tr > td:nth-child(7) {
        color: #99c4e1 !important;
    }

    .calendar-error-message {
        color: #e95532;
        margin-top: 10px;
    }

    .btn-close {
        width: 180px !important;
        border: 1px solid #006cb6 !important;
        border-radius: 27px !important;
        padding: 8px 32px 32px !important;

        .btn-close-text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: center;
            color: #006cb6;
        }

        .btn-icon {
            font-size: 14px;
            padding-right: 10px;
        }
    }

    .col-next {
        margin-left: 40px !important;
        text-align: right !important;
    }

    .btn-next {
        width: 320px !important;
        border-radius: 27px !important;
        border: 1px solid #006cb6 !important;
        background-color: #006cb6 !important;
        padding: 8px 32px 32px !important;

        .btn-next:disabled {
            color: gray !important;
            background-color: #ccc !important; /* Optional: Change background color */
            border-color: gray !important; /* Optional: Change border color */
        }

        .btn-next-text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
        }
    }
}
//end

.schedule-confirmation-modal {
    width: 700px !important;

    .title {
        font-size: 32px;
        line-height: 48px;
        font-weight: 700;
        text-align: center;
        color: #2e353a;
        margin-bottom: 40px !important;
    }

    .details-div-top {
        padding: 16px !important;
        border-top: 1px solid #d4e1ea;
        border-bottom: 1px solid #d4e1ea;
    }

    .details-div {
        padding: 16px !important;
        border-bottom: 1px solid #d4e1ea;
        margin-bottom: 20px !important;
    }

    .details-row {
        align-items: center !important;
    }

    .details-label {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-align: left;
        color: #2e353a;
        border-right: 1px solid #d4e1ea;
    }

    .details-value {
        white-space: pre-line;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-align: left;
        color: #2e353a;
        padding-left: 32px;
    }

    .details-date {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        text-align: left;
        color: #006cb6;
        padding-left: 32px;
    }

    .btn-back {
        width: 180px !important;
        border: 1px solid #006cb6 !important;
        border-radius: 27px !important;
        padding: 8px 32px 32px !important;

        .btn-back-text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: center;
            color: #006cb6;
        }

        .btn-icon {
            font-size: 14px;
            padding-right: 10px;
        }
    }

    .col-next {
        margin-left: 40px !important;
        text-align: right !important;
    }

    .btn-next {
        width: 320px !important;
        border-radius: 27px !important;
        border: 1px solid #006cb6 !important;
        background-color: #006cb6 !important;
        padding: 8px 32px 32px !important;

        .btn-next-text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
        }
    }
}

//new schedule modal
.new-schedule-modal {
    width: 800px !important;
    margin-top: -20px !important;

    .title {
        font-size: 32px;
        line-height: 48px;
        font-weight: 700;
        text-align: center;
        color: #2e353a;
        margin: 32px 0px !important;
    }

    .subtitle {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-align: center;
        color: #2e353a;
    }

    .subtitle-top {
        margin-bottom: 5px !important;
    }

    .subtitle-bottom {
        margin-bottom: 40px !important;
    }

    .new-schedule-div {
        padding: 16px !important;
        border-top: 1px solid #d4e1ea;
        border-bottom: 1px solid #d4e1ea;
    }

    .new-schedule-row {
        align-items: center !important;
    }

    .new-schedule-label {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-align: left;
        color: #2e353a;
        border-right: 1px solid #d4e1ea;
    }

    .new-schedule-date {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        text-align: left;
        color: #006cb6;
        padding-left: 32px;
    }

    .action-button {
        margin-top: 40px !important;
        text-align: center !important;
    }

    .btn-close {
        width: 180px !important;
        border: 1px solid #006cb6 !important;
        border-radius: 27px !important;
        padding: 8px 32px 32px !important;

        .btn-close-text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: center;
            color: #006cb6;
        }

        .btn-icon {
            font-size: 14px;
            padding-right: 10px;
        }
    }
}
//end
