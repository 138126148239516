.kisoku-dropdown {
    background-color: white;
    color: black;
    border-color: black;
    border-style: solid;
    border-width: 0px;
}

.kisoku-management-subtitle {
    font-family: Hiragino Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.kisoku-card-container {
    margin: 10px 0px;
}

.kisoku-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.kisoku-item {
    max-width: 110px;
    min-width: 110px;
    width: 100px;
    font-family: Hiragino Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    align-self: center;
}

.kisoku-action-button {
    max-width: 95px;
    min-width: 95px;
    font-family: Noto Sans JP;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
}

.kisoku-horizontal-line {
    border: 2px solid;
    margin: 10px 0px;
}

.container-margin {
    margin: 0 24px;
}

.status-info-icon {
    color: #999999;
    margin: 0px 2px;
}
