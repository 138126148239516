.kouteki-dropdown {
    background-color: white;
    color: black;
    border-color: black;
    border-style: solid;
    border-width: 0;
}

.kouteki-management-subtitle {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
}

.kouteki-card-container {
    margin: 10px 0;
}

.kouteki-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.lighter-text {
    color: #999999;
}

.status-info-icon {
    color: #999999;
    margin: 0 2px;
}
