.meeting-list {
    .title {
        margin-bottom: 10px;
    }

    &-header .calendar-options {
        font-size: 10px;
    }

    &-search {
        .status-search {
            margin-bottom: 10px;

            .meeting-select {
                width: auto;
                min-width: 0;
            }
        }

        .text-search .meeting-search {
            width: auto;
            min-width: 0;
            margin-bottom: 10px;
        }
    }
}
