@import '../../variables';

.interview-list {
    .ant-btn:not(.ant-btn-dangerous, .ant-btn-primary, [disabled]) {
        border: 1px solid #d9d9d9; // Default
        border-left: none;
        border-radius: 0 2px 2px 0 !important;
    }

    .ant-input {
        border-radius: 2px 0 0 2px !important;
    }

    .interview-list-pagination button, .interview-list-pagination li {
        border: none !important;
    }
}

.interview-list-card {
    padding: 15px 20px;
    border-radius: 3px;
    border: 1px solid rgba(196, 196, 196, 0.4);
    margin-top: 15px;
    background-color: $gray-1;

    .interview-list-card-titles {
        .interview-list-card-title {
            padding: 20px 0;
        }
    }

    .details-button {
        color: black !important;
        border: 1px solid $gray-4 !important;
        width: 100px;
    }

    &-title {
        font-size: 14px;
        font-weight: bold;
    }

    .status-info-icon {
        color: $gray-4;
    }
}

.interview-status-card {
    margin: 15px 0;

    .status-text {
        text-align: right;
        font-weight: 700;
    }

    .status-title {
        color: black;
    }

    .status {
        .ant-card-body {
            padding: 10px 20px;
        }

        &-matching {
            background: $orange-1;
            border-color: $orange-2;
            color: $orange-3;
        }

        &-selecting-specialist {
            background: $brown-1;
            border-color: $brown-2;
            color: $brown-3;
        }

        &-scheduled {
            background: $light-blue-1;
            border-color: $light-blue-2;
            color: $button-primary-color;
        }

        &-finished {
            background: $green-1;
            border-color: $green-2;
            color: $green-3;
        }

        &-no-match {
            background: $purple-1;
            border-color: $purple-3;
            color: $purple-3;
        }

        &-canceled {
            background: $red-1;
            border-color: $red-2;
            color: $red-3;
        }

        &-requesting {
            background: $teal-1;
            border-color: $teal-2;
            color: $teal-2;
        }

        &-waiting-conf {
            background: $orange-1;
            border-color: $orange-2;
            color: $orange-3;
        }
    }

    .fund-info {
        background: $yellow-1;
        border-color: $yellow-1;
        padding: 10px 0;
    }
}

.interview-specialist-card {
    margin: 15px 0;

    .ant-card {
        border-color: $gray-3;
        background: $gray-1;
    }

    .ant-image-img {
        border-radius: 100px;
        max-height: 70px;
    }

    button.edit-profile {
        font-size: 12px;
        height: 50px;
    }

    .label-text {
        color: $gray-5;
        font-weight: bolder;
    }

    .datetime {
        color: $green-3;
    }

    .attendance-check-icon {
        color: $button-primary-color;
        font-weight: bold;
    }

    .note-text {
        color: $red-3;
        margin-top: 10px;
    }

    .ant-collapse-header {
        display: block !important;
    }

    .profile-card {
        .ant-card-body {
            padding: 0;
        }

        &-title {
            font-weight: bold;
            color: $gray-5;
            margin: 5px 0;
        }

        ul {
            list-style: none;
        }

        ul li::before {
            color: $button-primary-color;
            content: "\2022";
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }

        &-schedule {
            padding: 0 20px;
        }

        &-spacing {
            margin: 15px 0;
        }

        &-note {
            color: $gray-5;
        }
    }
}

.interview-cancel-modal, .employee-management:not(.assign-company-modal) {
    button.modal-button {
        margin: 5px 0;
    }

    form {
        width: 400px !important;
    }

    .form-buttons {
        width: 300px;
        margin: auto;
    }
}

.interview-confirmation-modal {
    width: 800px !important;

    .interview-contents {
        background: $light-blue-1;
        border-color: $light-blue-1;

        .ant-card-body {
            padding: 15px 10px;
        }
    }

    .modal-note {
        color: $gray-5;
        margin-bottom: 10px;
    }
}
