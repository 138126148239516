@import 'src/assets/styles/variables';

body#landing {
    .container {
        margin: 55px auto;
    }

    .registration-card.preview .ant-form-item-extra {
        display: none;
    }

    section.header {
        position: relative;
        width: 100%;
        background-image: url("../../images/landing_header.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 70%;

        img {
            width: 30%;
        }

        .content {
            min-height: 800px;
            display: flex;
            justify-content: center;
            align-items: center;
            //background: linear-gradient(
            //        180deg,
            //        rgba(0, 151, 254, 0.7) -8.91%,
            //        rgba(0, 12, 20, 0.7) 74%
            //);

            .ant-card {
                margin: 100px 0;
            }
        }
    }

    .box-1 {
        background: url("../../images/pexels-rodnae-productions-7845351.jpg") center no-repeat;
    }

    .box-2 {
        background: url("../../images/pexels-rodnae-productions-7845376.jpg") center no-repeat;
    }

    .box-1, .box-2 {
        background-size: cover;
        position: relative;
        height: 400px;
        z-index: 1;
        padding: 50px 25px;
        color: #FFF;
        transition: all 0.3s ease-in-out;
        border-radius: $border-radius;

        &:after {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, #01A0DD 20.07%, rgba(0, 157, 219, 0.35) 61.42%);
            border-radius: $border-radius;
            z-index: -1;
        }

        &:hover {
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            transform: translateY(-2px);
        }

        .title {
            font-size: 36px;
        }

        .subtitle {
            font-size: 18px;
        }

        p {
            font-size: 14px;
            margin-top: 16px;
        }
    }

    footer.landing-footer {
        color: #656565;

        .footer-wrapper {
            background-color: #F2F2F2;
        }
    }

    section.sub-header {
        position: relative;
        background: url("../../images/kouteki_header.png") center no-repeat; // just change the image
        width: 100%;
        background-size: cover;

        img {
            width: 30%;
        }

        .content {
            background: rgba(19, 90, 117, 0.5);
            background-size: cover;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;

            .title {
                margin-top: 10%;
                font-size: 36px;
                color: #FFFFFF;
            }
        }
    }
}
