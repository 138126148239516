body#dashboard {
    @import 'components/meeting_list';
    @import 'components/cancel_modal';
    
    .dashboard-container {
        min-height: 100vh;
    }

    .dashboard-sider {
        overflow: unset;
        position: fixed;
        height: calc(100vh - 75px);
        left: unset;
        z-index: 101;
        margin-top: 75px;
    }

    .ant-layout-sider-zero-width-trigger {
        top: 0;
    }

    .dashboard-content {
        padding: 135px 20px 50px 20px;

        .ant-layout-content {
            margin-left: 0;
            flex: unset;
        }
    }

    .dashboard-management {
        .dashboard-profile {
            padding: 0;

            .ant-col {
                word-break: break-all;
            }
        }

        button.edit-profile {
            margin-left: 0;
            margin-bottom: 25px;
        }

        .title {
            margin-bottom: 10px;
        }
    }

    .dashboard-content > .ant-layout-content,
    .dashboard-content .content {
        width: 100%;
    }

    .kisoku-template-action-button,
    .diagnostic-export-action-button {
        margin-left: 0;
    }

    .diagnostic-export-management,
    .department-management,
    .branch-management,
    .employee-management,
    .customer-management,
    .kouteki-management,
    .kisoku-management,
    .customer-view-profile {
        .title {
            margin-bottom: 10px;
        }

        .header-container {
            flex-direction: column;
            margin-bottom: 10px;
        }

        .action-buttons {
            margin-left: 0;
        }
    }

    .customer-view-profile {
        .action-buttons.overlay-top-right {
            position: unset;
            z-index: 10;
            margin-bottom: 10px;
        }

        .view-profile-container {
            padding: 0;
        }

        .ant-col {
            word-break: break-all;
        }
    }

    .consultation-list-container .consultation-list-card {
        .consultation-schedule {
            text-align: left !important;
        }

        .ant-btn {
            width: 100%;
        }
    }

    .kisoku-management .kisoku-card-container {
        overflow: auto;
    }

    .kisoku-management {
        .kisoku-horizontal-line {
            width: 77vh;
        }

        .ant-card-body {
            width: max-content;
        }
    }
}
