.title-row {
    font-weight: 700;
    font-size: 12px;
    width: 90px;
}

.title-row-long {
    font-weight: 700;
    font-size: 12px;
}

.title-row,
.description-row {
    line-height: 30px;
}

.title-row-long,
.description-row-long {
    line-height: 30px;
}

.container-row {
    display: flex;
    flex-direction: row;
    width: inherit;
    margin-bottom: 5px;
}

.dashboard-management.content {
    height: 60vh;
}

.down-outline {
    line-height: 24px;
    float: right;
    font-size: 14px;
}

.item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.item-text {
    padding: 0 10px;
}
