@import 'src/assets/styles/variables';

body#dashboard {
    @import 'components/consultation';
    @import 'components/profile';
    @import 'components/management';
    @import 'components/kouteki';
    @import 'components/kisoku';
    @import 'components/employee_management';
    @import 'components/meeting_list';
    @import 'components/meeting_details';
    @import 'components/cancel_modal';
    @import 'components/change_schedule_modal';

    background: #f0f2f5;

    .ant-menu-root {
        flex-direction: column;
        display: flex;
        height: 100%;
        border-right: none;
    }

    .secondary-button {
        background-color: $button-secondary-color;
        color: black;
        border: 1px solid $button-secondary-outline;
        min-width: 100px;
    }

    .success-button {
        background-color: $green-3;
        color: white;
        border: 1px solid $green-3;
        min-width: 100px;
    }

    .dashboard-content {
        padding: 135px 175px 100px;
        flex-direction: row;

        .ant-layout-sider {
            padding: 25px 0;
            max-height: calc(100% - 250px);
            background-color: #fff;
            overflow-x: hidden;
            border-radius: 4px;
        }

        .ant-menu-item-selected {
            background-color: #006bb5;

            &,
            a {
                color: #fff;
            }

            &:after {
                border: none;
            }
        }

        .ant-layout-content {
            margin-left: 300px;
            background-color: #ffffff;
            padding: 32px !important;
            border-radius: 4px;
        }

        .ant-breadcrumb {
            margin-bottom: 10px;
        }

        .content {
            position: relative;
            background-color: #fff;
        }

        .notification-count {
            float: right;
            margin: 10px;
        }

        .ant-badge-count {
            box-shadow: 0 0 0 1px $red-3;
        }
    }

    .dashboard-sider {
        overflow: auto;
        height: 100vh;
        position: fixed;
        left: 175px;
    }

    .dashboard-footer {
        background-color: $button-primary-color;
        text-align: center;
        height: 30px;
        padding: 5px 0;
        color: #fff;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .dashboard-management {
        button.edit-profile {
            float: right;
            background: $button-primary-color;
            color: #fff;
        }

        .personal-info {
            .title {
                font-size: 16px;
                margin-bottom: 5px;
            }
        }

        button.unsubscribe-button {
            float: right;
        }

        .change-password {
            color: #01a0dd;
            text-decoration: underline;
            margin-bottom: 45px;
            cursor: pointer;
        }
    }

    .branch-management {
        button.edit-profile {
            float: right;
            background: $button-primary-color;
            color: #fff;
        }

        button.unsubscribe-button {
            float: right;
        }

        .change-password {
            color: #01a0dd;
            text-decoration: underline;
            margin-bottom: 45px;
            cursor: pointer;
        }
    }
    
    .change-sched-primary-btn:disabled {
        color: gray !important;
        background-color: #ccc !important;
        border-color: #ccc !important;
    }

    .dashboard-notifications {
        .notification {
            &-container {
                padding: 10px 20px;
                border-radius: 3px;
                border: 1px solid rgba(196, 196, 196, 0.4);
                margin-bottom: 15px;

                &_read {
                    background-color: $gray-1;
                }

                &_unread {
                    background-color: $yellow-1;
                }
            }

            &-title {
                font-weight: bold;
                font-size: 14px;
            }

            &-btn {
                background-color: $button-secondary-color;
                color: black;
                border: 1px solid $button-secondary-outline;
                width: 100px;
            }
        }
    }

    .unsubscribe-modal {
        .unsubscribe-content {
            padding: 30px;
        }

        .unsubscribe-description {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0;
            text-align: center;
        }
    }

    .interview-list {
        .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .table-filter {
            float: right;
        }

        .subtitle {
            font-size: 16px;
            //color: $gray-4;
        }

        &-pagination {
            margin: 15px 0;
        }
    }

    .interview-details {
        .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .grant-checklist {
            margin: 10px 0;
        }

        .consultation-detail {
            margin: 10px 0;
        }

        .cancel-button {
            float: right;
        }

        button.back-button {
            width: 200px;
            margin-top: 30px;
        }

        button.consult {
            margin-top: 30px;
        }

        .consult-note {
            margin: 20px auto;
            width: 60%;
        }
    }

    .dashboard-calendar {
        .meeting-count {
            padding: 15px 20px;
            border-radius: 3px;
            border: 1px solid rgba(196, 196, 196, 0.4);
            margin-bottom: 15px;
            background-color: $gray-1;

            &-title {
                font-size: 16px;
                font-weight: bold;
            }
        }

        .divider {
            margin: 30px 0;
            height: 2px;
            border: none;
            background-color: $button-primary-color;
        }

        .subtitle {
            font-size: 16px;
            color: $gray-4;
        }

        .ant-picker-body {
            z-index: 0;
        }

        .month-picker {
            text-align: right;

            &-text {
                font-weight: bold;
                font-size: 16px;
            }

            .ant-btn {
                border: none;
                color: black;
            }
        }

        ul {
            list-style-type: none !important;
            padding-inline-start: 0;
        }
    }

    .diagnostic-export-button {
        margin-left: 10px;
        line-height: 0;
        height: 30px;
    }

    .kisoku-template-action-button,
    .diagnostic-export-action-button {
        margin-left: 20px;
    }

    .diagnostic-export-management,
    .kisoku-template-management,
    .department-management,
    .branch-management,
    .employee-management,
    .customer-management,
    .kouteki-management,
    .kisoku-management,
    .export-management,
    .customer-view-profile {
        .header-container {
            display: flex;
        }

        .action-buttons {
            margin-left: auto;

            button.filter-button {
                color: black;
                border: 1px solid $gray-6;
                padding: 3px 10px;
                background: white;
            }

            .ant-input-search-button {
                border: 1px solid $gray-6 !important;
                background-color: white;
            }

            .ant-btn-dangerous {
                background: $red-1;
            }

            .green-button {
                background: transparent;
                border: 1px solid $green-3;
                color: $green-3;
                text-shadow: none;
            }

            .green-button:hover {
                border: 1px solid $green-2;
                color: $green-2;
            }
        }

        .ant-list-item {
            display: block;
            padding: 5px 0;
        }

        .ant-list-split .ant-list-item {
            border-bottom: none;
        }

        .ant-collapse {
            border: 1px solid $gray-1;
        }

        .ant-collapse-item {
            border-bottom: $gray-1;
        }

        .ant-collapse .ant-collapse-item {
            background: $gray-1;
        }

        .ant-collapse-content {
            border-top: 1px solid $gray-1;
        }

        .ant-collapse-header {
            display: block;
        }

        .branch-sorter {
            margin-left: 10px;
            font-size: 14px;
            font-weight: bold;

            a {
                color: black;
            }
        }

        .custom-table {
            .action-buttons {
                float: none;

                .ant-btn {
                    min-width: 100px;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }

                .edit-button {
                    background: $light-blue-1;
                    color: $button-primary-color;
                    border: 1px solid $button-primary-color;
                }

                button.delete-button {
                    background: $red-1;
                    color: $red-3;
                    border: 1px solid $red-3;
                }
            }

            .ant-table {
                background: none;
            }

            .ant-table-thead > tr > th {
                border-bottom: 2px solid $gray-6;
                background: none;
            }

            .ant-table-thead
                > tr
                > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
                    [colspan]
                )::before {
                display: none;
            }

            .ant-table-tbody > tr > td {
                border-bottom: none;
                padding: 5px 16px;
            }

            &.highlighted .ant-table-row {
                background-color: $gray-1;
                border: 1px solid rgba(196, 196, 196, 0.4);
            }
        }
    }

    .department-management-modal,
    .branch-management-modal,
    .assign-company-modal,
    .customer-management-modal {
        .ant-modal-body {
            padding: 50px 10%;
        }

        .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .message {
            text-align: center;
            margin: 10px 10px;
        }
    }

    .customer-view-profile {
        .ant-tabs-content {
            position: relative;
        }

        .action-buttons.overlay-top-right {
            position: absolute;
            right: 20px;
            top: -60px;
            z-index: 10;
        }

        .view-profile-container {
            padding: 0 50px;
        }

        .personal-info {
            .title {
                font-size: 16px;
                margin-bottom: 5px;
            }
        }
    }

    .assign-company-modal,
    .transfer-employee-branch-modal {
        width: 600px !important;

        .ant-modal-body {
            padding: 50px 15%;
        }
    }

    .assign-company-modal {
        .ant-modal-body {
            padding: 56px 3rem 3rem 3rem;
        }
        .ant-form-item-explain {
            display: none !important;
        }
    }

    .employee-management {
        .card-container {
            background: $gray-2;
            border: 1px solid $gray-2;
        }

        .w-95 {
            width: 95%;
            margin: auto;
        }

        .title {
            margin-bottom: 10px;
        }

        &-edit {
            form {
                width: 100% !important;
            }
        }
    }

    .select-status {
        width: 200px;
        margin-right: 10px;
    }

    .search {
        width: 200px;
    }

    .ant-dropdown-menu {
        .ant-menu-item-selected {
            background-color: #006bb5;

            &,
            a {
                color: #fff;
            }

            &:after {
                border: none;
            }
        }
    }
}

@media (max-width: 1440px) {
    body#dashboard {
        .dashboard-content {
            padding: 135px 35px 100px 60px;

            .content {
                width: auto;
            }
        }
        .dashboard-sider {
            left: auto;
        }
    }
}
