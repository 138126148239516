@import 'src/assets/styles/variables';

body#employee-register {
    background-image: url('../../images/register_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    .ant-layout {
        background: none;
    }

    .registration-card {
        width: 60%;
        margin: 50px auto;

        .title {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 15px;
            text-align: center;
        }

        .registration-success-card {
            width: 600px;
            margin: 15vh auto;
            padding: 30px 0;
        }

        form {
            width: 90%;
        }

        .register-buttons {
            width: 200px;
        }
    }
}
