body#employee-register {
    .registration-card {
        width: 95%;
        margin: 50px auto;
    }

    .register-buttons {
        width: 40%;
    }

    .registration-success-card {
        width: 95%;
    }
}
