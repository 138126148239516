.profile-title {
    font-size: 20px;
    font-weight: 700;
}

.profile-subtitle {
    font-size: 14px;
}

.profile-forgot-password-link {
    font-size: 12px;
    color: #01a0dd;
    margin-bottom: 20px;
}
