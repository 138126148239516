@import '../variables';

body {
    margin: 0;
    font-family: 'Noto Sans JP', sans-serif;
}

.loader {
    background: url('../../images/loader.gif') rgba(255, 255, 255, 0.5) center no-repeat;
    background-size: 7%;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    transition: 0.3s all ease-in-out;
    opacity: 1;
}

.helper-text {
    font-size: 12px;
    color: #747272;
}

.fade-enter {
    opacity: 0;
}

.fade-exit {
    opacity: 1;
}

.fade-enter-active {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
    transition: opacity 300ms;
}

.ant-btn,
.ant-modal-content,
.ant-input {
    border-radius: $border-radius !important;
}

.ant-input[readonly] {
    background: #f5f5f5;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.breadcrumbs {
    padding: 60px 182px 30px 182px;
}

.text-center {
    text-align: center;
}

.ant-carousel {
    .slick-list {
        margin: 0 30px;
    }

    .slick-prev,
    .slick-next {
        top: 0;
        height: 100%;
        display: flex !important;
        align-items: center;
        margin: 0;
        font-size: 24px;
        background: white !important;
    }

    .slick-prev,
    .slick-next,
    .slick-prev:hover,
    .slick-next:hover {
        color: currentColor;
        z-index: 2;
    }

    .slick-prev,
    .slick-prev:hover {
        left: 2px;
    }

    .slick-next,
    .slick-next:hover {
        right: 2px;
    }
}

.login-modal,
.success-modal,
.forgot-password-card,
.forgot-password-modal,
.change-password-modal,
.interview-cancel-modal,
.custom-confirmation-modal {
    width: 600px !important;
}

.eligibility-modal,
.profile-preview-modal {
    width: 800px !important;
}

.login-modal,
.success-modal,
.forgot-password-card,
.forgot-password-modal,
.registration-card,
.eligibility-modal,
.change-password-modal,
.interview-cancel-modal,
.custom-confirmation-modal,
.registration-success-card,
.profile-preview-modal {
    .ant-modal-body {
        padding: 50px 13%;
    }

    .title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
        text-align: center;
    }

    .subtitle {
        font-size: 12px;
        text-align: center;
    }

    form {
        margin: 35px auto;
        width: 300px;

        .link {
            display: block;
            margin: 20px 0;
            font-size: 13px;
            color: #656565;
        }
    }

    .helper-text {
        font-size: 12px;
        color: #747272;

        a {
            text-decoration: none;
        }
    }

    .action-buttons {
        .ant-btn {
            width: 200px;
        }

        .secondary-button {
            width: 200px !important;
        }

        .success-button {
            width: 200px !important;
        }
    }
}

.profile-preview-modal {
    .label {
        font-weight: bold;
        display: inline;
        color: $gray-5;
    }
}

.ant-btn-primary {
    background-color: $button-primary-color;
    color: #ffffff;
    border: none;
}

button.ant-btn {
    box-shadow: none;
}

.success-modal {
    text-align: center;
    align-content: center;

    .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    img {
        margin: 30px;
    }

    .message {
        margin: 10px 10px;
    }
}

.forgot-password-card {
    width: 600px;
    padding: 56px 100px;
    position: fixed;
    top: 25%;
    left: 35%;
    bottom: 25%;
}

.importing-modal, .import-failed {
    .title {
        font-size: 18px;
        font-weight: bold;
    }
}

.required-mark:before {
    content: '* ';
    color: $red-3;
}

.system-notification-container {
    background: #0461A2;
    padding: 10px 136px;
    height: $notification-height;
    z-index: 1;

    p {
        color: white;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 0;
    }
}

#dashboard .system-notification-container {
    position: fixed;
    width: 100%;
    padding: 10px 110px;
}

.system-notification-container + .dashboard-navbar {
    top: $notification-height;
}

.system-notification-container + .dashboard-navbar + .dashboard-content {
    padding-top: calc(135px + $notification-height) !important;
}

@each $width in 100px, 200px, 300px {
    .w-#{$width} {
        width: $width;
    }
}

.me-10px {
    margin-right: 10px;
}

.ms-20px {
    margin-left: 20px;
}

.me-20px {
    margin-right: 20px;
}

.p-10px {
    padding: 10px;
}

.mt-30px {
    margin-top: 30px;
}

.px-25px {
    padding-left: 25px;
    padding-right: 25px;
}

.my-30px {
    margin-top: 30px;
    margin-bottom: 30px;
}

.border-light-blue-3 {
    border-color: $light-blue-3;
}

.list-style-none {
    list-style: none;
}

/**
   Reusable custom color classes
 */
$colors: (
    primary-color: $button-primary-color,
    green-3: $green-3,
    red-3: $red-3,
);

@each $className, $color in $colors {
    .#{$className} {
        color: $color !important;
    }
}
