.dashboard-navbar {
    padding: 5px 10%;

    .logo {
        img {
            height: 35px;
            align-self: start;
        }

        span {
            font-size: 10px;
        }
    }

    .buttons .button{
        display: flex;
        align-items: center;
        font-size: 10px;
        padding: 0px 0px 0px 5px;

        span {
            font-size: 10px !important;
        }
    }
}
.sub-title {
    font-size: 10px !important;
    margin-bottom: 5px;
}
